.App {
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  flex: 1;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.Sidebar {
  background-color: #333;
  color: white;
  width: 225px; /* Adjust the width as needed */
  flex-shrink: 0;
  transition: width 0.3s ease; /* Add smooth transition animation */
  height: 91vh;
}

.Sidebar.minimized {
  width: 50px; /* Adjust the width when minimized */
}

.Sidebar ul {
  list-style-type: none;
  padding: 0;
}

.Sidebar li {
  padding: 10px 20px;
}

/* Add hover effect for items in the sidebar */
.Sidebar li:hover {
  background-color: #444;
}

/* Add styling for links in the sidebar */
.Sidebar a {
  color: white;
  text-decoration: none;
}

/* Add styles for a minimized sidebar */
.Sidebar.minimized ul,
.Sidebar.minimized li {
  padding: 0;
}

.Sidebar.minimized li:hover {
  background-color: transparent;
}

/* Add styles for the toggle button */
.ToggleButton {
  cursor: pointer;
  padding: 10px;
  background-color: #282c34;
  color: white;
  text-align: center;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.ToggleButton:hover {
  background-color: #444;
}